import React from "react"
import { Calendar } from "react-feather"
import { Carousel } from "../Carousel"
import containerClasses from "../../styles/container.module.css"
import starRate from "../../assets/images/star_rate.svg"

export const Reviews = () => {
  return (
    <div className="py-12 bg-brilliantWhite md:py-15 lg:py-20">
      <div className={`${containerClasses.container} `}>
        <div className="col-span-4 md:col-span-8">
          <p className="my-0 overline">TESTIMONIALS</p>
          <h2 className="fluid-heading-05 lg:text-left">
            <i>Happy</i> patients
          </h2>
        </div>
        <div className="col-span-4 md:col-span-8 lg:col-span-12">
          <Carousel>
            {/* Review - 10 Feb 2025 */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                “Dr Hawkes is outstanding. I had an upper eye Bleph with her.
                The initial appointment was super informative. She took me
                through the options - no pressure at all. We did measurements of
                the eye and a full eye examination. We discussed in detail what
                the outcomes would be, I felt very happy and comfortable
                progressing with her as my surgeon. On the day of the procedure
                I was very well looked after under sedation at the Cadogan
                Clinic. I decided to have a GA but she can also perform the
                procedure under sedation. To say I'm delighted with the results
                is an understatement, I look fresher / younger and feel great! I
                had no pain after the procedure and no bruising at all. She
                gives very detailed post op instructions which I followed to the
                letter. I found the whole experience very easy and
                straightforward. I would highly recommend Dr Hawkes.”
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  FEBRUARY, 2025
                </p>
              </div>
            </div>

            {/* Review - 04 Feb 2025 */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                “I am really pleased with the results I've had with Dr Hawkes.
                She was very personable and spoke to me as an individual which I
                appreciated having seen other surgeons before choosing to
                proceed with her. The communication has been great and the whole
                team are available if you have any questions or just need some
                reassurance. I especially appreciated the thoughtful care
                package for post surgery!”
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  FEBRUARY, 2025
                </p>
              </div>
            </div>

            {/* Review - 08 Jan 2025 */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                “This was an extremely difficult revision case for a fat
                transfer under my eyes gone wrong. Three previous revision
                surgeries failed to work. I was rejected by Daniel Ezra and told
                the surgery would cause a retraction. Other surgeons were also
                unprepared to take me on. My only option appeared to be seeing
                Dr. Guy Massry in the US, who takes on these kinds of cases with
                success. However, Ms Hawkes was brave enough to take me on and I
                had a 90% improvement- and no retraction! She is the UK's Dr
                Massry! Highly recommended.”
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  JANUARY, 2025
                </p>
              </div>
            </div>

            {/* Review - 07 Jan 2025 */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                “Extremely happy with the result of my lower eye lid surgery.
                Very natural look”
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  JANUARY, 2025
                </p>
              </div>
            </div>

            {/* Review - 07 Jan 2025 */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                “Dr.Elizabeth was incredible. Every single aspect of my
                treatment be it consultation to treatment to aftercare was 5
                star!!!!! I couldn’t be happier with my results and she’s given
                me a fresh new feel x Over the moon with both Dr.Elizabeth and
                the Cadogan Clinic”
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  JANUARY, 2025
                </p>
              </div>
            </div>

            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                “My experience from start to finish was exemplary. Ms Hawkes is
                professional and through and she explained everything clearly,
                answering any questions. I am overjoyed with the results!”
              </p>

              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  December, 2024
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                “If there was an A** for my experience I would give it. Miss
                Hawkes is just great in every aspect. She is so knowledgeable,
                explains everything and has a lovely calming manner. I was very
                scared and had thought about this procedure for many years but
                as soon as I had my initial consultation with her I knew I was
                in very experienced and safe hands. I feel that she goes way
                beyond expectations and cannot recommend her more highly. A big
                thank you x”
              </p>

              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  November, 2024
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                “I have had a few aesthetic treatments with Dr Elizabeth now,
                namely Botox, polynucleotides and profilho. Each and every time
                Dr Elizabeth has been very approachable and informative
                regarding details and processes. She is always happy to answer
                any questions I may have. I am really happy with my results so
                far having had no downtime nor bruising which I know is down to
                her preferred cannula techniques. I am still mid treatment plan
                but the improvements I am seeing already are very impressive!
                The clinic and staff I cannot fault either, always friendly and
                helpful. Their communication has been excellent as well. I
                highly recommended Dr Elizabeth, her team and clinic.”
              </p>

              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  August, 2024
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                “Outstanding. Having researched a number of doctors prior to
                booking this procedure, Elizabeth Dolatshahy Hawkes gave me
                great confidence in her experience during a consultation prior
                to surgery. Explanations of the process and expectations were
                clear. Importantly, her photo’s of her work were her own and not
                generic. The results were excellent. I would definitely
                recommend her services.”
              </p>

              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  June, 2024
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                “Very professional and informative, transparent and honest.”
              </p>

              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  February, 2024
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                “I found the consultation with Dr Hawkes very informative and
                that we covered any questions I had. It was great to see before
                and after photos of upper eyelids, and Dr Hawkes explained
                possible risks as well, as I understand there are with any
                medical or surgical procedure.”
              </p>

              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  January, 2024
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;I’ve already passed on my recommendation to two friends &
                one colleague. One week after surgery, I have a significant
                increase in range of vision and no longer have a ‘heavy eyes’
                feeling. I look fresher and like ‘me’ again. So happy; wish I’d
                done this years earlier but now delighted with the
                results.&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  December, 2023
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;From the initial consultation with Dr. Hawkes until my
                one week follow up today, I have been so impressed. She is at
                the top of the field with expertise on blepharoplasty and I had
                done a lot of research before choosing her . She is totally
                passionate about giving tailor made advice for all of her
                patients. A wonderful bedside manner and totally transparent
                with advice . The clinic is spotless. The whole experience has
                been wonderful and I felt in very safe hands . I am astounded
                after one week to see the transformation and look so much more
                refreshed and will be so excited to see the final result.
                Stitches removed today after one week, totally painless . I
                would totally recommend Dr. Hawkes and her lovely team at the
                Cadogan Clinic.&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  October, 2023
                </p>
              </div>
            </div>

            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;Absolutely love visiting Dr Elizabeth for botox, she
                always puts me at ease and explains exactly what I can expect
                from treatment!&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  September, 2023
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;Elizabeth explained procedure clearly and in a friendly
                manner. Was very reassuring&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  June, 2023
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col col-span-12 px-8 py-12 border-solid border-1 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;I had upper and lower blepharoplasty a little over a week
                ago with Elizabeth Hawkes at the Cadogan Clinic. The whole
                experience from initial consultation to operation and post op
                visit was superb. Elizabeth has a kind, natural and caring
                approach as do all her team. Her skill levels are incredibly
                good and I couldn’t be more pleased with the operation results,
                even only 8 days post op. I would recommend Elizabeth Hawkes and
                the Cadogan clinic to anyone looking for great results, with the
                whole process carried out in a lovely caring manner, but
                professional at all times.&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  May, 2023
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full col-span-12 px-12 py-12 border-solid border-1 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;Dr Elizabeth Hawkes is just lovely and absolutely Amazing
                at what she does. I came to Dr Hawkes seeking a subtle natural
                look. Dr Hawkes has the eye of an artist. I am so thrilled with
                the results! I feel most fortunate to have discovered her.
                Incredibly professional and knowledgeable in her expertise and
                advice, I always feel I am in the safest of hands with her care
                and attention to detail. I highly recommend the fabulous Dr
                Elizabeth Hawkes. Thank you Dr Hawkes! Gráinne&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  April, 2023
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;Extremely pleased with my how refreshed my eyes are now
                following Upper Blepharoplasty in December. Friends have
                commented on how amazing they look, and even my husband has said
                ... its the best thing I&apos;ve had done. Thank you so very
                much&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  January, 2023
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;I am extremely happy with my eye blepharoplasty . I
                highly recommend Elizabeth Hawkes my procedure from start to
                finish went so smoothly and I felt very comfortable.&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  January, 2023
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;Dr Hawkes was really informative at the initial
                consultation, and made me feel at ease with the procedure.
                Throughout the entire process from consultation to surgery day,
                she was extremely professional. I am so pleased with the results
                and would highly recommend her.&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  December, 2022
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;I am thrilled with the results of my upper and lower lid
                blepharoplasty surgery. Elizabeth was very considerate and
                understanding of my apprehension; she has an excellent bedside
                manner and put me totally at ease. The healing process for me
                was straightforward and pain-free. I could not be happier and
                more delighted, that I decided to go ahead with the surgery and
                that through my research, I chose Elizabeth to be my surgeon. I
                have no hesitation in highly recommending her.&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  October, 2022
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;I am absolutely thrilled with the results of my Botox
                treatment to improve the symmetry in my eyebrows. Miss Hawkes is
                professional with an excellent bedside manner and I would
                definitely recommend her.&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  August, 2022
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;I have had the best experience with Elizabeth. My face
                went from being very tired to very youthful and rested. She
                explains everything very thoroughly and listens to all your
                concerns. She is always happy to see you after a few days and
                make sure everything is on point.&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  August, 2022
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;I found Miss Hawkes to be very approachable, kind and
                very professional. I was able to call or e-Mail if I had any
                concerns and she would always reply as soon as possible. My
                recovery from my surgery was longer than I expected, mostly due
                to severe swelling, but Miss Hawkes reassured me that all the
                issues would settle in time. Now everything has settled, I am
                very satisfied with the surgery and would highly recommend Miss
                Hawkes to anyone considering eye surgery.&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  July, 2022
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;I&#39;m thrilled with my decision to put my trust in
                Elizabeth Hawkes for my blepharoplasty. From the initial
                consultation through to the final signoff after my procedure, I
                felt completely confident. My expectations were managed well and
                I am over the moon with the results.&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  April, 2022
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;I was delighted with the results of my lower
                blepharoplasty with Ms Hawkes, she explained the procedure
                clearly and the results I should expect. I felt in very safe
                hands throughout.&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  April, 2022
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;Elizabeth was very clear in her explanations and ensured
                that I was fully aware of any potential risks - she was very
                considered in her approach and was excellent in managing my
                expectations.&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  October, 2021
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;Ms Hawkes was caring, understanding and extremely
                professional. She explained everything in a way that is easily
                understood. As part of my treatment I had a blepharoplasty upper
                eyelid lift and I am overwhelmed of how much difference it has
                made. My eyes have opened up and I have a fresher look. I didn’t
                tell many people, but I get a lot of comments now of how “well I
                look”. I am really impressed with the results.&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  April, 2021
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;I chose Dr Elizabeth Hawkes to have my lips augmented as
                after seeing her a couple of times, I completely trusted her
                with my lips! She takes the time to make sure you feel
                comfortable and talks through what treatment is best for you.
                I&apos;m so happy with the end results and couldn&apos;t
                recommend her more. She is also a pleasure to visit too!&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  April, 2021
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;I have researched for a long time to find someone I can
                trust with my upper eyelid surgery. I chose Elizabeth because
                she is specialised in eyes only and she is passionate about the
                health of the eye from the inside as well as the cosmetic part
                of it. At our first meeting she examined the health of my eye
                and then talked about the desired result of the upper eyelid
                surgery.&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  April, 2021
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;Elizabeth was the perfect consultant: she thought
                carefully about what would be best for me having discussed at
                length my needs. Her manner was impeccable: thoughtful,
                intelligent and I had complete confidence in her expertise. She
                performed such a brilliant op on my eyes that people remarked
                how well I looked, but couldn’t tell I had had eye surgery. The
                whole experience was very positive and I highly recommend
                her.&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  April, 2021
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;Elizabeth performed blepharoplasty and a brow lift on me
                7 weeks ago at the Cadogan Clinic and I am absolutely thrilled
                with the results. She has done an exceptional job and I cannot
                recommend her more highly. She clearly explained the procedure
                always answering any questions and put my mind at ease pre and
                post the operation.&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  September, 2020
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;Miss Elizabeth Hawkes is at the top of the craft and you
                couldn&apos;t ask for a more knowledgeable, sociable and honest
                doctor. I went for information on eye bag removal and instead
                got the most valuable info that has helped me resolve an eye
                issue I&apos;ve been suffering with for almost 30 years. I am
                ecstatic and delighted that she was able to diagnose my
                condition, she is extremely knowledgeable in both plastic
                surgery and ophthalmology and I have complete trust in
                her.&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  February, 2020
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;Elizabeth was professional and knowledgeable, and
                listened patiently to my concerns regarding botox and fillers
                before fully explaining the treatment process. I had full
                confidence in Elizabeth&apos;s abilities and was absolutely
                thrilled with the results. I won&apos;t hesitate to book in with
                Elizabeth again.&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  January, 2020
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;I saw Dr Hawkes for filler treatments to the lower face
                for the first time last week. I have been having injectable
                treatments for years. It was a fantastic visit! She has a very
                gentle, warm and caring approach. I felt very safe in her hands
                and she explains things really well and guided me on the options
                to enhance and contour my features. Treatment was very
                comfortable, hardly any swelling and no bruising. Results are
                beautifully natural, I am thrilled! Thank you so much!&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  December 2019
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;I had an eyelid lesion removed by Miss Elizabeth Hawkes.
                The treatment I received was first class! The surgery was
                painless and have praised by family and friends about the
                natural appearance. No scarring and minimal bruising, can&apos;t
                even see where the lump was removed. All the staff were very
                friendly and helpful, in the operating theatre and on the
                ward.&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  September 2019
                </p>
              </div>
            </div>
            {/* Review */}
            <div className="flex flex-col h-full p-8 border-solid brilliantWhite border-1 md:p-12 lg:p-16 border-borderColour md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3">
              <div className="flex items-center mb-2 text-gold600">
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 -mr-1"
                  src={starRate}
                  alt="Star"
                />
                <img
                  loading="lazy"
                  className="h-5 mr-2"
                  src={starRate}
                  alt="Star"
                />
              </div>
              <p
                className="flex-grow pb-8 font-mono font-thin leading-tight text-blackNew multi-line-truncate"
                style={{ maxHeight: "14rem" }}
              >
                &#8220;Elizabeth was so welcoming and kind and reassuring and
                took so much time to go through everything and test my eyes, and
                my Dad&apos;s eyes. I was with my Dad and he was really nervous
                but relaxed very quickly once he met Elizabeth. She was also
                very gentle and patient and her manner is fantastic, as is her
                knowledge, clearly. She also took time to explain using diagrams
                the situation for my Dad, and it was so interesting to actually
                understand the function of the parts of the eye..&#8221;
              </p>
              <div className="flex flex-row items-center content-center mt-1 mb-0 text-blackNew">
                <Calendar size="16" className="mr-2" />
                <p className="mt-0 mb-0 font-mono text-sm font-bold tracking-wider text-gray-400 uppercase">
                  {" "}
                  July 2019
                </p>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  )
}
